import React from "react";
import { Helmet } from "react-helmet";
import aist from "../images/aist.svg"

const SEO = ( ) => {
  const seo = {
    title: "Aiст Технологии | ИИ Решения для Анализа Корпоративных Данных",
    description: "Aiст Технологии предлагает передовые ИИ-инструменты для автоматизации анализа корпоративных данных, финансовых отчетов и документации.",
    url: `https://admirable-griffin-7aa9a7.netlify.app}`,
    image: aist, // Replace with a default image path
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <link rel="canonical" href={seo.url} />}
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
};

export default SEO;
